/**
 * Main navigation
 */
bs_main_nav = function() {
  
  var $ = jQuery;
  var _elem = {};
  var _data = { expanded: false };
  
  var _private = {
    init: function() {
      _elem.container = $('#nav-mobile-container');
      _elem.trigger = $('#nav-toggle');
      _elem.overlay = $('#overlay');
      
      _elem.trigger.on('click', function() {
        (_data.expanded) ? _public.collapse() : _public.expand();
      });
      
      _elem.overlay.on('click', function() {
        _public.collapse();
      });
      
      return _public;
    },
  }
  
  var _public = {
    expand: function() {
      _elem.container.addClass('active');
      _elem.trigger.addClass('active');
      _elem.overlay.addClass('active');
      _data.expanded = true;
    },
    collapse: function() {
      _elem.container.removeClass('active');
      _elem.trigger.removeClass('active');
      _elem.overlay.removeClass('active');
      _data.expanded = false;
    }
  }
  
  return _private.init();
  
};

bs_main_nav_tools = function() {
  
  var $ = jQuery;
  var _elemTools = {};
  var _data = { expanded: false };
  
  var _private = {
    init: function() {
      _elemTools.containerTools = $('#nav-mobile-container-tools');
      _elemTools.trigger = $('#nav-toggle-tools');
      _elemTools.overlay = $('#overlay-tools');
      
      _elemTools.trigger.on('click', function() {
        (_data.expanded) ? _public.collapse() : _public.expand();
      });
      
      _elemTools.overlay.on('click', function() {
        _public.collapse();
      });
      
      return _public;
    },
  }
  
  var _public = {
    expand: function() {
      _elemTools.containerTools.addClass('active');
      _elemTools.trigger.addClass('active');
      _elemTools.overlay.addClass('active');
      _elemTools.expanded = true;
    },
    collapse: function() {
      _elemTools.containerTools.removeClass('active');
      _elemTools.trigger.removeClass('active');
      _elemTools.overlay.removeClass('active');
      _elemTools.expanded = false;
    }
  }
  
  return _private.init();
  
};


/**
* Sub Navigation
*/
bs_subnav = function() {
  
  var $ = jQuery;
  var _elem = {};
  var _data = { expanded: false };
  
  var _private = {
    init: function() {
      _elem.container = $('#subnav');
      _elem.trigger = $('#subnav-trigger');
      _elem.icon = _elem.trigger.find('i');
      
      _elem.trigger.on('click', function() {
        (_data.expanded) ? _public.collapse() : _public.expand();
      });
      
      return _public;
    }
  }
  
  var _public = {
    enable: function() {},
    disable: function() { _public.collapse(); },
    expand: function() {
      _elem.container.addClass('active');
      _elem.trigger.addClass('active');
      _elem.icon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
      _data.expanded = true;
    },
    collapse: function() {
      _elem.container.removeClass('active');
      _elem.trigger.removeClass('active');
      _elem.icon.removeClass('fa-chevron-down').addClass('fa-chevron-right');
      _data.expanded = false;
    }
  }
  
  return _private.init();
  
};

/**
* Header search
*/
bs_header_search = function(f) {
  var $ = jQuery;
  var _elem = {}
  var _active = false;
  var _private = {
    init: function() {
      _elem.form = $('#header-container.www .searchform');
      _elem.input = $('#header-container.www .search-input');
      _elem.button = $('#header-container.www .search-submit');
      _elem.close = $('<i class="search-close fa-solid fa-xmark"></i>');
      
      _elem.close.appendTo(_elem.form);
      _elem.button.on('click', function(e) {
        if(!_active) {
          e.preventDefault();
          _public.activate();
        }
      });
      _elem.close.on('click', function(e) {
        if(_active) {
          _public.deactivate();
        }
      });
      return _public;
    },
  }
  
  var _public = {
    activate: function() {
      _elem.form.addClass('active');
      _elem.input.focus();
      _active = true;
    },
    deactivate: function() {
      _elem.form.removeClass('active');
      _active = false;
    }
  }
  return _private.init(f);
}

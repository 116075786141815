/**
 * Expanders
 */
bs_expander = function(obj) {

  var $ = jQuery;
  var _elem = {}

  var _private = {
    init: function(obj) {
      _elem.container = obj;
      _elem.header = obj.find('.bs-expand-header');
      _elem.link = obj.find('.bs-expand-link');
      _elem.content = obj.find('.bs-expand-content');

      _elem.container.data('expanded', false);
      _elem.link.on('click', _private.toggle);
    },

    toggle: function(e) {
      e.preventDefault();
        if(_elem.container.data('expanded') == true) {
          _elem.content.slideUp(300);
          _elem.container.data('expanded', false).removeClass('bs-expand-active');
        } else {
          _elem.content.slideDown(300);
          _elem.container.data('expanded', true).addClass('bs-expand-active');
        }
    }
  }

  var _public = {}

  return _private.init(obj);

};

/**
 * Tabs
 */
bs_tabs = function(obj) {

  var $ = jQuery;
  var _elem = {};

  var _private = {
    init: function(obj) {
      _elem.tabgroup = obj;
      _elem.tabscontainer = obj.find('.bs-tabs');
      _elem.tabs = obj.find('.bs-tab');
      _elem.links = obj.find('.bs-tablink');
      _elem.tabpanels = obj.find('.bs-tabpanel');

      _elem.links.on('click', _public.activate);
      
      _elem.links.first().trigger('click');
    }
  }

  var _public = {
    activate: function(e) {
      e.preventDefault();
      _public.deactivate();
      var link = $(this);
      var panel = _elem.tabpanels.filter('[data-panelid=' + link.data('tabtarget') + ']');
      link.addClass('active');
      panel.addClass('active');
    },
    deactivate: function() {
      _elem.links.removeClass('active');
      _elem.tabpanels.removeClass('active');
    }
  }

  return _private.init(obj);

};

/**
 * Accordions
 */
bs_accordion = function(obj) {

  var $ = jQuery;
  var _elem = {}

  var _private = {
    init: function(obj) {

      _elem.root = obj;
      _elem.containers = obj.find('.bs-accordion');
      _elem.headers = obj.find('.bs-accordion-header');
      _elem.links = obj.find('.bs-accordion-link');
      _elem.contents = obj.find('.bs-accordion-content');

      _elem.containers.data('expanded', false);
      _elem.links.on('click', _private.toggle);
    },

    toggle: function(e) {
      e.preventDefault();
      var current = {
        container: $(this).parents('.bs-accordion'),
        content: $(this).parents('.bs-accordion').find('.bs-accordion-content')
      }
      console.log(_elem.contents);
      _elem.containers.not(current.container).data('expanded', 'false').removeClass('bs-accordion-active');
      _elem.contents.not(current.content).slideUp(300);
      if(current.container.data('expanded') == true) {
        current.container.data('expanded', false).removeClass('bs-accordion-active');
        current.content.slideUp(300);
      } else {
        current.container.data('expanded', true).addClass('bs-accordion-active');
        current.content.slideDown(300);
      }
    }
  }

  var _public = {}

  return _private.init(obj);

};
